@import url('https://fonts.googleapis.com/css?family=Sen:regular,bold,italic&subset=latin,latin-ext');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.devotion {
  font-family: 'Sen', regular;
  font-size: 2.5em;
}